import {Controller} from "@hotwired/stimulus";

export default class ScrollTo extends Controller {
  get offset() {
    return 10;
  }

  get behavior() {
    return "smooth";
  }

  connect() {
    this.element.addEventListener("click", this.scroll);
  }

  disconnect() {
    this.element.removeEventListener("click", this.scroll);
  }

  scroll(event) {
    event.preventDefault();

    const id = this.href.split("#")[1];
    const target = document.getElementById(id);

    if (!target) {
      console.warn(`[stimulus-scroll-to] The element with the id: "${id}" does not exist on the page.`);
      return;
    }

    const elementPosition = target.getBoundingClientRect().top + window.pageYOffset;
    console.log("elementPosition", elementPosition)
    const offsetPosition = elementPosition - this.offset;
    console.log("offsetPosition", offsetPosition)
    window.scrollTo({
      top: offsetPosition,
      behavior: this.behavior,
    });
  }
}

